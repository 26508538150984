import { PageContext } from '~/renderer/types.ts'
import validateAccess from '~/utils/access-validator.ts'
import validateSession from '~/utils/session-validator.ts'

export const guard = (pageContext: PageContext) => {
  const { session } = pageContext

  validateSession(session)
  validateAccess(session)
}
